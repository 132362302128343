import React, {FormEvent} from "react";
import {Button, Form, SpaceBetween} from "@amzn/awsui-components-react/polaris";

export const RegisterMfaForm = ({loggedInUsername, error, onSubmit}: RegisterMfaFormProps) => {
    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onSubmit();
    }

    return (
        <form onSubmit={handleSubmit}>
            <Form
                actions={<Button variant="primary" data-testid="register-button">Register</Button>}
                errorText={error}
            >
                <SpaceBetween direction="vertical" size="l">
                    <p>You are registering a key for <strong>{loggedInUsername}</strong>. Click the "Register" button below to begin the key registration process.</p>
                </SpaceBetween>
            </Form>
        </form>
    )
}