import React, {useEffect, useState} from "react";
import {useAuth} from "react-oidc-context";
import {
    Alert,
    BreadcrumbGroup,
    Container,
    ContentLayout,
    Header,
    SpaceBetween
} from "@amzn/awsui-components-react/polaris";
import {PageLayout} from "../components/page-layout";
import {RegisterOtpKeyForm} from "../components/register-otp-key-form";
import {useOTPKeyRegister} from "../hooks/otp";
import {EnableUserManagement} from "../constants/admin";

const Breadcrumbs = () => {
    const breadcrumbItems = [
        {href: '#', text: 'Key Management'},
        {href: '/register-otp-key', text: 'Register OTP'},
    ];

    return (
        <BreadcrumbGroup
            items={breadcrumbItems}
            expandAriaLabel="Show path"
            ariaLabel="Breadcrumbs"
        />
    )
}

const ShowRegisterAlerts = (items: RegisterAlerts) => {
    return (
        <SpaceBetween direction="vertical" size="xs">
            {Object.keys(items).map((key: string) => (
                <Alert key={key} statusIconAriaLabel={items[key].ariaLabel} type={items[key].type}>
                    {items[key].message}
                </Alert>
            ))}
        </SpaceBetween>
    );
}

export const RegisterOtpKey = () => {
    const auth = useAuth();
    const [alerts, setAlerts] = useState<RegisterAlerts>({})
    const {registerKey, keyError, isKeySuccess } = useOTPKeyRegister();
    const otpEnabled = auth.user?.profile?.otp_enabled as boolean ?? false
    const userManagementEnabled = EnableUserManagement(auth)
    useEffect(() => {
        if (isKeySuccess) {
            setAlerts(alerts => ({
                ...alerts, "successfully_registered_alert": {
                    message: "Your MFA token was successfully registered! You can now access the Kuiper network.",
                    ariaLabel: "Success",
                    type: "success",
                }
            }));
        }
    }, [isKeySuccess])

    return (
        <PageLayout sideNavOpen={true} loggedInUsername={auth.user?.profile?.name} otpEnabled={otpEnabled} userManagementEnabled={userManagementEnabled}>
            <ContentLayout
                notifications={ShowRegisterAlerts(alerts)}
                breadcrumbs={Breadcrumbs()}
                headerVariant="high-contrast"
                header={<Header variant="h1">OTP Security Key Registration</Header>}
                defaultPadding={true}
                maxContentWidth={1040}
                disableOverlap={false}
            >
                <Container header={<Header variant="h2">Register your OTP key</Header>}>
                    <RegisterOtpKeyForm loggedInUsername={auth.user?.profile?.preferred_username} error={keyError} onSubmit={registerKey}/>
                </Container>
            </ContentLayout>
        </PageLayout>
    );
}