import React, {useEffect, useState} from 'react';
import {hasAuthParams, useAuth} from "react-oidc-context";
import {useDispatch} from 'react-redux';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {Home} from "./pages/home";
import {RegisterMFA} from "./pages/register-mfa";
import {UserManagement} from "./pages/user-management";
import {setUser} from './app/reducer';
import {RegisterOtpKey} from "./pages/register-otp-key";
import {SetOtpPin} from "./pages/set-otp-pin";
import {EnableUserManagement} from "./constants/admin";
import {RegisterKeyWizard} from "./pages/register-wizard";
import {useAppSelector} from './app/selector';

function App() {
    const dispatch = useDispatch();
    const auth = useAuth();
    const [hasTriedSignin, setHasTriedSignin] = useState(false)
    const hasTokens = useAppSelector((state) => state.app.hasWebAuthnCreds);

    // automatically sign-in
    useEffect(() => {
        if (!hasAuthParams() &&
            !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading &&
            !hasTriedSignin
        ) {
            auth.signinRedirect();
            setHasTriedSignin(true);
        }
    }, [auth, hasTriedSignin]);

    if (auth.isLoading) {
        return <div>Signing you in...</div>;
    }

    if (!auth.isAuthenticated) {
        return <div>Unable to log in</div>;
    }

    const isOtpEnabled = () => {
        return auth.user?.profile?.otp_enabled as boolean ?? false
    }

    dispatch(setUser({userToken: auth.user?.id_token ?? ''}));

    if (!hasTokens) {
        return <RegisterKeyWizard/>
    }

    return (
        <Router>
            <Routes>
                <Route path="/" Component={Home}/>
                <Route path="/register-mfa" Component={RegisterMFA}/>
                {EnableUserManagement(auth) && (
                    <Route path="/user-management" Component={UserManagement}/>
                )}
                {isOtpEnabled() && (
                    <Route path="/register-otp-key"  Component={RegisterOtpKey}/>
                )}
                {isOtpEnabled() && (
                    <Route path="/set-otp-pin" Component={SetOtpPin}/>
                )}
            </Routes>
        </Router>
    );
}

export default App;
