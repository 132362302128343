import React from "react";
import {ContentLayout, Header, SpaceBetween} from "@amzn/awsui-components-react/polaris";
import {PageLayout} from "../components/page-layout";
import {useAuth} from "react-oidc-context";
import {UserManagementSearch} from "../components/user-management-search";
import {EnableUserManagement} from "../constants/admin";

export const UserManagement = () => {
    const auth = useAuth();
    // TODO: Re-enable - this will get directs from manager
    //const {data} = useDirectUsersQuery(auth.user?.profile?.sub ?? "");
    const otpEnabled = auth.user?.profile?.otp_enabled as boolean ?? false
    const userManagementEnabled = EnableUserManagement(auth)

    return (
        <PageLayout sideNavOpen={false} loggedInUsername={auth.user?.profile?.name} otpEnabled={otpEnabled} userManagementEnabled={userManagementEnabled}>
            <ContentLayout
                headerVariant="high-contrast"
                header={<Header variant="h1" description="Search for and manage users">User Management</Header>}
                defaultPadding={true}
                maxContentWidth={1080}
                disableOverlap={false}
                data-testid="admin-page"
            >
                <SpaceBetween size="m" direction="vertical">
                    <UserManagementSearch/>
                    {/* <UserManagementCards users={data.users}/> */}
                </SpaceBetween>

            </ContentLayout>
        </PageLayout>
    );
}