
import {Button, Container, Form, FormField, Input, SpaceBetween} from "@amzn/awsui-components-react/polaris";
import {BadgePhotoBaseUrl} from "../constants/admin";
import {GenerateToken} from "./generate-token";
import React, {FormEvent, useState} from "react";
import {useSearchUserHook} from "../hooks/search";
import {Box, ExpandableSection} from "@amzn/awsui-components-react";

export const UserManagementSearch = () => {
    const [alias, setAlias] = useState("")
    let errMsg = ""
    let {
        data,
        handleSearch,
        isError,
        error,
    } = useSearchUserHook();

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        await handleSearch(alias)
    }
    if (isError) {
        data = null
        if (error.data && error.data.code === 51500) {
            errMsg = "user not found"
        } else if (error.data && error.data.code === 51501) {
            errMsg = "user not in direct hierarchy"
        } else if (error.data && error.data.code === 51502) {
            errMsg = "malformed input"
        } else {
            errMsg = "unknown error";
        }
    }
    return (
        <Container>
            <SpaceBetween direction="vertical" size="s">
                <form style={{margin: '16px'}} onSubmit={handleSubmit}>
                    <Form
                        actions={<Button variant="primary" data-testid="search-button">Search</Button>}
                        data-testid="search-user-form"
                        errorText={errMsg}
                    >
                        <SpaceBetween direction="vertical" size="s">
                            <FormField label="Search User"
                                       data-testid="user-alias-form-field"
                                       description="Enter user alias that you want to search">
                                <Input value={alias}
                                       onChange={(e) => setAlias(e.detail.value.trim())}
                                       data-testid="new-alias-input"/>
                            </FormField>
                        </SpaceBetween>
                    </Form>
                </form>

                {data && (<Container>
                        <SpaceBetween direction="horizontal" alignItems={"center"} size="s">
                            <img src={`${BadgePhotoBaseUrl}?uid=${data.user.Alias}`}
                                 alt="" style={{blockSize: "50px", borderRadius: "10%"}}/>
                            <SpaceBetween direction="vertical" size="xxxs">
                                <Box variant="h2">
                                    {data.user.DisplayName}
                                </Box>
                                <Box variant="h5">
                                    {data.user.Alias}
                                </Box>
                                <ExpandableSection headerText="Generate registration token">
                                    <GenerateToken user={data.user}></GenerateToken>
                                </ExpandableSection>
                            </SpaceBetween>
                        </SpaceBetween>
                    </Container>)
                }
            </SpaceBetween>
        </Container>
    )
}