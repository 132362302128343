import { useState } from "react";
import {
  preformatMakeCredReq,
} from "../utils/webauthn";
import {
  useRegisterMfaMutation,
  useSendAuthnResponseMutation,
} from "../services/idp/idp";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";
import {
  RegistrationResponse,
} from "../services/idp/idp.types";

export const useRegister = (): {
  register: () => Promise<
    | void
    | {
        data: void;
        error?: FetchBaseQueryError | SerializedError;
      }
  >;
  isError: boolean;
  isSuccess: boolean;
  isLoading: boolean;
  error: string;
} => {
  const [error, setError] = useState("");
  const [registration, preRegState] = useRegisterMfaMutation();
  const [authn, regState] = useSendAuthnResponseMutation();

  const register = () => {
    setError("");
    return registration()
      .unwrap()
      .then((response: RegistrationResponse) => {
        const publicKey = preformatMakeCredReq(response.publicKey);
        return navigator.credentials.create({publicKey});
      })
      .then((response: any) => {
        const c = response;
        const pKey = c.response.getPublicKey();
        const pKeyAlg = c.response.getPublicKeyAlgorithm();
        const authData = c.response.getAuthenticatorData();

        const credential = {
          authenticatorAttachment: c.authenticatorAttachment,
          id: c.id,
          rawId: bufferToBase64url(c.rawId),
          response: {
            attestationObject: bufferToBase64url(c.response.attestationObject),
            clientDataJSON: bufferToBase64url(c.response.clientDataJSON),
            publicKey: bufferToBase64url(pKey),
            publicKeyAlgorithm: pKeyAlg,
            authenticatorData: bufferToBase64url(authData),
            transports: c.transports,
          },
          type: c.type,
        };
        return authn({credential})
      }).then((response: any) => {
          if (response.error?.data && response.error?.data.code === 53001) {
            setError("Too many tokens. You have already reached the maximum of 2 tokens for your user.")
          } else if (response.error?.data) {
            setError("Something when wrong and we could not register your token, please try again later");
          }
          return response;
      })
      .catch((error: any) => {
        console.log(error)
        setError(error.error);
        return error;
      });
  }

  return { 
    register, 
    error,
    isError: preRegState.isError || regState.isError, 
    isSuccess: regState.isSuccess, 
    isLoading: preRegState.isLoading || regState.isLoading
  };
};

function bufferToBase64url(buffer: any) {
  // modified from https://github.com/github/webauthn-json/blob/main/src/webauthn-json/base64url.ts

  const byteView = new Uint8Array(buffer);
  let str = "";
  for (const charCode of byteView) {
    str += String.fromCharCode(charCode);
  }

  // Binary string to base64
  const base64String = btoa(str);

  // Base64 to base64url
  // We assume that the base64url string is well-formed.
  const base64urlString = base64String
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=/g, "");
  return base64urlString;
}
