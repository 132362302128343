import React, {useEffect, useState} from "react";
import {useAuth} from "react-oidc-context";
import {Alert, BreadcrumbGroup, ContentLayout, Header, SpaceBetween} from "@amzn/awsui-components-react/polaris";
import {PageLayout} from "../components/page-layout";
import {useOTPKeyRegister, useSetOTPPin} from "../hooks/otp";
import {RegisterWizardTabs} from "../components/register-wizard-tabs";
import {useRegister} from "../hooks/webauthn";
import {EnableUserManagement} from "../constants/admin";

const Breadcrumbs = () => {
    const breadcrumbItems = [
        {href: '#', text: 'Key Management'},
        {href: '/register', text: 'Register security key'},
    ];

    return (
        <BreadcrumbGroup
            items={breadcrumbItems}
            expandAriaLabel="Show path"
            ariaLabel="Breadcrumbs"
        />
    )
}

const ShowRegisterAlerts = (items: RegisterAlerts) => {
    return (
        <SpaceBetween direction="vertical" size="xs">
            {Object.keys(items).map((key: string) => (
                <Alert key={key} statusIconAriaLabel={items[key].ariaLabel} type={items[key].type}>
                    {items[key].message}
                </Alert>
            ))}
        </SpaceBetween>
    );
}

export const RegisterKeyWizard = () => {
    const auth = useAuth();
    const [alerts, setAlerts] = useState<RegisterAlerts>({})
    const {register, error, isSuccess } = useRegister();
    const {setPin, pinError, isPinSuccess } = useSetOTPPin();
    const {registerKey, keyError, isKeySuccess } = useOTPKeyRegister();

    const otpEnabled = auth.user?.profile?.otp_enabled as boolean ?? false
    const userManagementEnabled = EnableUserManagement(auth)

    const clearAlerts = () => {
        setAlerts({})
    }

    useEffect(() => {
        if (!window.chrome) {
            setAlerts(prevAlerts => ({
                ...prevAlerts, "browser_compatability_warning": {
                    message: "There are known issues with registering FIDO2 keys in this browser. Please use Chrome for the best registration experience.",
                    ariaLabel: "Warning",
                    type: "warning",
                }
            }));
        }
    }, []);

    useEffect(() => {
        if (isSuccess) {
            setAlerts(prevAlerts => ({
                ...prevAlerts, "successfully_registered_alert": {
                    message: `Your security key was successfully registered! ${otpEnabled ? "If you need to use OTP, you can now move on to the next step." : "You can now access the Kuiper network."}`,
                    ariaLabel: "Success",
                    type: "success",
                }
            }));
        }
    }, [isSuccess, otpEnabled])

    useEffect(() => {
        if (isPinSuccess) {
            setAlerts(alerts => ({
                ...alerts, "successfully_registered_alert": {
                    message: "Your OTP PIN has been successfully set! You can now move on to the next step.",
                    ariaLabel: "Success",
                    type: "success",
                }
            }));
        }
    }, [isPinSuccess])

    useEffect(() => {
        if (isKeySuccess) {
            setAlerts(alerts => ({
                ...alerts, "successfully_registered_alert": {
                    message: "Your security key was successfully registered for OTP! You can now login to Kuiper services and devices.",
                    ariaLabel: "Success",
                    type: "success",
                }
            }));
        }
    }, [isKeySuccess])

    return (
        <PageLayout sideNavOpen={false} loggedInUsername={auth.user?.profile?.name} otpEnabled={otpEnabled} userManagementEnabled={userManagementEnabled}>
            <ContentLayout
                notifications={ShowRegisterAlerts(alerts)}
                breadcrumbs={Breadcrumbs()}
                headerVariant="high-contrast"
                header={<Header variant="h1">Security Key Registration</Header>}
                defaultPadding={true}
                maxContentWidth={1040}
                disableOverlap={false}
            >
                <RegisterWizardTabs loggedInUsername={auth.user?.profile?.preferred_username} otpEnabled={otpEnabled} clearAlerts={clearAlerts} fidoError={error} onFidoSubmit={register} pinError={pinError} onPinSubmit={setPin} otpError={keyError} onOtpSubmit={registerKey}/>
            </ContentLayout>
        </PageLayout>
    );
}