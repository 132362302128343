import React, { useState } from "react";
import {Container} from "@amzn/awsui-components-react/polaris";
import Tabs from "@amzn/awsui-components-react/polaris/tabs";
import {RegisterMfaForm} from "./register-mfa-form";
import {RegisterOtpKeyForm} from "./register-otp-key-form";
import {SetOtpPinForm} from "./set-otp-pin-form";


export const RegisterWizardTabs = ({loggedInUsername, otpEnabled, fidoError, onFidoSubmit, pinError, onPinSubmit, otpError, onOtpSubmit, clearAlerts}: RegisterKeyFormProps) => {
    const [currentTabId, setCurrentTabId] = useState<string>("first");

    const tabs = [
        {
            label: "Step 1: Register key",
            id: "first",
            content: (
                <RegisterMfaForm loggedInUsername={loggedInUsername} error={fidoError} onSubmit={onFidoSubmit}/>
            )
        },
    ];

    if (otpEnabled) {
        tabs.push(
            {
                label: "Step 2: Set OTP PIN",
                id: "second",
                content: (
                    <SetOtpPinForm loggedInUsername={loggedInUsername} error={pinError} onSubmit={onPinSubmit}/>
                )
            },
            {
                label: "Step 3: Register OTP",
                id: "third",
                content: (
                    <RegisterOtpKeyForm loggedInUsername={loggedInUsername} error={otpError} onSubmit={onOtpSubmit}/>
                )
            }
        )
    }

    return (
        <Container>
            <Tabs
                onChange={({ detail }) => {
                    setCurrentTabId(detail.activeTabId);
                    clearAlerts();
                }
                }
                activeTabId={currentTabId}
                tabs={tabs}
            />
        </Container>
    )
}