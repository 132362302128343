import React from 'react';
import {SideNavigation, SideNavigationProps} from '@amzn/awsui-components-react/polaris';

// for now, while we have few pages with simple paths, we can use window.location.pathname (with trailing slashes
// removed, since /page and /page/ are the same) to set the current active ref; as we add more pages or more complex
// navigation item hrefs, consider exposing activeHref as a prop
const activeHref = window.location.pathname.replace(/\/+$/, '');

export const DefaultSideNavigation = ({otpEnabled, userManagementEnabled}: SideNavProps) => {
    const defaultNavHeader = {text: 'Kuiper IdP', href: '/'};
    const keyNavItems: SideNavigationProps.Item[] = [
        {type: 'link', text: 'Register security key', href: '/register-mfa'},
    ]
    const userNavItems: SideNavigationProps.Item[] = [
        {type: 'link', text: 'Users', href: '/user-management'},
    ]
    if (otpEnabled) {
        keyNavItems.push(
            {type: 'link', text: 'Set OTP PIN', href: '/set-otp-pin'},
            {type: 'link', text: 'Register OTP', href: '/register-otp-key'},
        )
    }

    let defaultNavItems: SideNavigationProps['items'] = [
        {
            type: 'section',
            text: 'Key Management',
            items: keyNavItems,
        }
    ]
    if (userManagementEnabled) {
        defaultNavItems = [...defaultNavItems, {
            type: 'section',
            text: 'User Management',
            items: userNavItems,
        }]
    }

    return <SideNavigation items={defaultNavItems} header={defaultNavHeader} activeHref={activeHref}/>;
}
